import Vue from 'vue'
import Vuex from 'vuex'
import axios from '../plugins/axios'

Vue.use(Vuex)

Vue.use({
  install(Vue) {
    Vue.prototype.axios = axios
  }
})

export default new Vuex.Store({
  state: {
    user: {},
    projects: null,
    selectedProject: null,
    adminProjects: [],
    selectedAdminProject: {},
    newProject: {},
    loading: false,
    collabs: [],
    clients: [],
    selectedAdminProjectID: null,
    selectedProjectID: null,
    selectedCollabID: null,
    hasUnsavedChanges: false,
    deleted: {
      cats: [],
      scats: [],
      apts: []
    }
  },
  getters: {
    newProject: state => { return state.newProject },
    user: state => { return state.user },
    selectedAdminProject: state => { return state.selectedAdminProject },
    selectedAdminProjectID: state => { return state.selectedAdminProjectID },
    selectedProjectID: state => { return state.selectedProjectID },
    selectedCollabID: state => { return state.selectedCollabID },
    deleted: state => { return state.deleted }
  },
  mutations: {
    setSelectedProject(state, project) {
      state.selectedProject = project
    },
    setSelectedAdminProjectID(state, ID) {
      state.selectedAdminProjectID = ID,
        state.selectedAdminProject = null
    },
    setSelectedProjectID(state, ID) {
      state.selectedProjectID = ID,
        state.selectedProject = null
    },
    setSelectedCollabID(state, ID) {
      state.selectedCollabID = ID
    },
    setProjects(state, projects) {
      state.projects = projects
    },
    setSelectedAdminProject(state, project) {
      state.selectedAdminProject = project
    },
    setAdminProjects(state, adminProjets) {
      state.adminProjects = adminProjets
    },
    setUser(state, user) {
      state.user = user
    },
    setNewProject(state, project) {
      state.newProject = project
    },
    setLoading(state, loading) {
      state.loading = loading
    },
    setCollabs(state, collabs) {
      state.collabs = collabs
    },
    setClients(state, clients) {
      state.clients = clients
    }
  },
  actions: {
    newStore(context) {
      context.commit("setUser", {});
      context.commit("setProjects", []);
      context.commit("setSelectedProject", null);
      context.commit("setAdminProjects", []);
      context.commit("setSelectedAdminProject", null);
      context.dispatch("createNewProject");
    },
    refreshProjects(context) {
      context.commit("setLoading", true);
      context.commit("setProjects", []);
      axios.get("/getSessions.php?id=" + context.getters.user.id)
        .then((response) => {
          if (response.data != "ko") {
            context.commit("setProjects", response.data);
            context.commit("setLoading", false);
          }
        })
    },
    refreshSelectedProject(context) {
      context.commit("setLoading", true);
      context.commit("setSelectedProject", null);
      axios.get("/getSession.php?iduser=" + context.getters.user.id + "&idprojet=" + context.getters.selectedProjectID)
        .then(async (response) => {
          if (response.data != "ko") {
            context.commit("setSelectedProject", response.data);
            context.commit("setLoading", false);
          }
        })
    },
    refreshProjectsAdmin(context) {
      context.commit("setLoading", true);
      context.commit("setAdminProjects", []);
      axios.get("/getSessionsAdmin.php?email=" + context.getters.user.user)
        .then(async (response) => {
          if (response.data != "ko") {
            context.commit("setAdminProjects", response.data);
            context.commit("setLoading", false);
          }
        })
    },
    refreshSelectedAdminProject(context) {
      context.commit("setLoading", true);
      axios.get("/getSessionAdmin.php?email=" + context.getters.user.user + "&ID=" + context.getters.selectedAdminProjectID)
        .then(async (response) => {
          if (response.data != "ko") {
            context.commit("setSelectedAdminProject", response.data);
            context.commit("setLoading", false);
            context.getters.deleted.cats = [];
            context.getters.deleted.scats = [];
            context.getters.deleted.apts = [];
          }
        })
    },
    refreshSelectCollabSession(context) {
      context.commit("setLoading", true);
      context.commit("setSelectedProject", null);
      axios.get("/getSession.php?iduser=" + context.getters.selectedCollabID + "&idprojet=" + context.getters.selectedAdminProjectID)
        .then(async (response) => {
          if (response.data != "ko") {
            context.commit("setSelectedProject", response.data);
            context.commit("setLoading", false);
          }
        })
    },
    refreshCollabs(context) {
      var bodyFormData = new FormData()
      bodyFormData.append('user', context.getters.user.user);
      axios.post("/getCollabs.php", bodyFormData).then(async (response) => {
        if (response.data != "ko") {
          context.commit("setCollabs", response.data.collabs)
          context.commit("setClients", response.data.clients);
        }
      })
    },
    resetCollabs(context) {
      for (let collab of context.state.collabs)
        collab.selected = false
    },
    saveAdminProjectChanges(context) {
      context.commit("setLoading", true);
      let data = new FormData();
      let t = context.getters.selectedAdminProject
      data.set('back', JSON.stringify({
        ID: t.ID,
        title: t.title,
        imgsrc: t.imgsrc,
        dateDebut: t.dateDebut,
        delai: t.delai,
        powerbisrc: t.powerbisrc,
        formulaire: t.forumulaire,
        deleted: context.getters.deleted
      }))
      axios.
        post(
          "/saveChanges.php",
          data
        ).then((response) => {
          console.log(response.data)
          context.state.hasUnsavedChanges = false
          context.dispatch("refreshSelectedAdminProject");
        })
    },
    createNewProject(context) {
      context.commit("setNewProject", {
        title: "",
        delai: 1,
        date: new Date().toLocaleDateString("en-CA"),
        categories: [],
        collabs: [],
        managers: [context.getters.user.user],
        imgsrc: ''
      });
      context.dispatch("resetCollabs");
    }
  }
})

import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/projets',
    name: 'ProjetsRouter',
    component: () => import(/* webpackChunkName: "projet" */ '../views/ProjetCollab/ProjetsG.vue'),
    children: [
      {
        path: '/projets',
        name: 'Projets',
        component: () => import(/* webpackChunkName: "projet" */ '../views/ProjetCollab/Projets.vue'),
      },
      {
        path: '/projets/:id',
        name: 'Projet',
        component: () => import(/* webpackChunkName: "projet" */ '../views/ProjetCollab/Projet.vue'),
        children: [
          {
            path: '',
            redirect: 'general'
          },
          {
            path: 'general',
            name: 'General',
            component: () => import(/* webpackChunkName: "projet" */ '../views/ProjetCollab/General.vue'),
          },
          {
            path: 'saisie',
            name: 'Saisie',
            component: () => import(/* webpackChunkName: "projet" */ '../views/ProjetCollab/Saisie.vue'),
          }
        ]
      }
    ]
  },
  {
    path: '/adminProjets',
    name: 'AdminProjetsRouter',
    component: () => import(/* webpackChunkName: "projet" */ '../views/ProjetManager/AdminProjetsG.vue'),
    children: [
      {
        path: '/adminProjets',
        name: 'AdminProjets',
        component: () => import(/* webpackChunkName: "projet" */ '../views/ProjetManager/AdminProjets2.vue'),
      },
      {
        path: '/adminProjets/addProjet',
        name: 'AddProjet',
        component: () => import(/* webpackChunkName: "projet" */ '../views/ProjetManager/addProjet2/addProjet2.vue'),
        /*children: [
          {
            path: 'step1',
            name: 'Step1',
            component: () => import( '../views/ProjetManager/addProjet/Step1.vue')
          },
          {
            path: 'step2',
            name: 'Step2',
            component: () => import('../views/ProjetManager/addProjet/Step2.vue'),
          },
          {
            path: 'step3',
            name: 'Step3',
            component: () => import('../views/ProjetManager/addProjet/Step3.vue'),
          },
          {
            path: 'step4',
            name: 'Step4',
            component: () => import('../views/ProjetManager/addProjet/Step4.vue'),
          },
          {
            path: 'step5',
            name: 'Step5',
            component: () => import('../views/ProjetManager/addProjet/Step5.vue'),
          }
        ]*/
      },
      {
        path: '/adminProjets/:id',
        name: 'adminProjet',
        component: () => import(/* webpackChunkName: "projet" */ '../views/ProjetManager/Projet/AdminProjet.vue'),
        children: [
          {
            path: '',
            redirect: 'general'
          },
          {
            path: 'Membres',
            name: 'Membres',
            component: () => import(/* webpackChunkName: "projet" */ '../views/ProjetManager/Projet/Membres.vue'),
            /*children : [
              {
                name : 'detailCollab',
                path : '',
                component: () => import( webpackChunkName: "projet" *'../views/ProjetManager/Projet/Detail.vue'),
              }
            ]*/
          },
          {
            path: 'Progression',
            name: 'Progression',
            component: () => import(/* webpackChunkName: "projet" */ '../views/ProjetManager/Projet/Progression.vue'),
          },
          {
            path: 'form',
            name: 'Formulaire',
            component: () => import(/* webpackChunkName: "projet" */ '../views/ProjetManager/Projet/FormulaireOLD.vue'),
          },
          {
            path: 'general',
            name: 'General',
            component: () => import(/* webpackChunkName: "projet" */ '../views/ProjetManager/Projet/General.vue'),
          }
        ]
      }
    ]
  },
  {
    path: '*',
    name: 'notFound',
    component: () => import(/* webpackChunkName: "projet" */ '../views/notFound.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import msal from 'vue-msal'



Vue.config.productionTip = false
Vue.use(msal, {
     auth: {
          clientId: "c6701e56-6f31-4a76-af87-92dd0c8848a0",
          authority: "https://login.microsoftonline.com/groupe-vital.com",
          //redirectUri: "http://localhost:8888",
          redirectUri: "https://v-comp.groupe-vital.com",
          validateAuthority: true,
          navigateToLoginRequestUrl: true,
     },
     request: {
          scopes: ["api://c6701e56-6f31-4a76-af87-92dd0c8848a0/access_as_user"]
     },
     cache: {
          cacheLocation: "sessionStorage", // This configures where your cache will be stored
     }
})

new Vue({
     router,
     store,
     render: h => h(App)
}).$mount('#app')





<template>
     <b-row style="max-width:1400px;margin:100px auto">
          <b-col class="px-3" style="min-width:400px;min-height: 300px;">
               <h4 class="text-center">Connection Manager</h4>
               <div class="mt-5  d-flex justify-content-center">
                    <button @click="login" class="mt-3 btn-microsoft d-flex align-items-center">
                         <div class="btn-microsoft-logo">
                              <img src="@/assets/o365.png" style="height: 35px" />
                         </div>
                         <div class="px-2">
                              Connection avec le compte O365
                         </div>
                    </button>
               </div>
          </b-col>
          <b-col class="px-3" style="min-width:400px">
               <h4 class="text-center">Connection Collaborateur</h4>
               <div class="mt-5">
                    <b-alert v-model="error" variant="danger" class="wobble-hor-bottom" dismissible>
                         Identifiants incorrects
                    </b-alert>
                    <b-form @submit="onSubmit">
                         <b-form-group id="fieldset-1" label="Identifiant : " label-for="input-1">
                              <b-input-group>
                                   <b-input-group-prepend is-text>
                                        <b-icon icon="person"></b-icon>
                                   </b-input-group-prepend>
                                   <b-form-input v-model="username" id="input-1" type="text" placeholder="Identifiant"
                                        required trim />
                              </b-input-group>
                         </b-form-group>
                         <b-form-group id="fieldset-1" label="Mot de passe : " label-for="input-2">
                              <b-input-group>
                                   <b-input-group-prepend is-text>
                                        <b-icon icon="lock"></b-icon>
                                   </b-input-group-prepend>
                                   <b-form-input v-model="password" id="input-2" :type="passwordFieldType" required
                                        trim />
                                   <b-input-group-append>
                                        <b-input-group-text v-on:click="showPassword">
                                             <b-icon :icon="icon"> </b-icon>
                                        </b-input-group-text>
                                   </b-input-group-append>
                              </b-input-group>
                         </b-form-group>
                         <b-form-checkbox class="ml-1" v-model="rememberMe" id="input-3">Se souvenir de moi
                         </b-form-checkbox>
                         <b-form-group class="mt-3 d-block float-right">
                              <b-button type="submit" variant="outline-primary">Se connecter
                                   <b-icon icon="box-arrow-in-right" />
                              </b-button>
                              <div class="mt-2">
                              </div>
                         </b-form-group>
                    </b-form>
               </div>
          </b-col>
     </b-row>
</template>

<script>
import { msalMixin } from 'vue-msal';

export default {
     name: "Login",
     mixins: [msalMixin],
     data() {
          return {
               username: localStorage.getItem("userName"),
               password: localStorage.getItem("passWord"),
               error: false,
               passwordFieldType: "password",
               icon: "eye",
               show: false,
               rememberMe: localStorage.getItem("rememberMe"),
          };
     },
     async created() {
          
          console.log(this.$msal.isAuthenticated())
          if (this.$msal.isAuthenticated()) {
               this.$store.commit("setLoading", true);
               await this.$msal.acquireToken()
               const sessionData = this.$msal.data
               const username = this.$msal.data.user.userName
               const accessToken = sessionData.accessToken
               if (accessToken) {
                    
                    localStorage.setItem("AUTHORIZATION", accessToken)
                    this.axios.defaults.headers = {
                         Authorization: `Bearer ${localStorage.getItem("AUTHORIZATION")}`
                    }
                    this.axios.get("/ms-auth.php?username=" + username, {
                         headers: {
                              'Content-Type': 'application/json',
                              'Authorization': `Bearer ${localStorage.getItem("AUTHORIZATION")}`
                         }
                    }).then((response) => {
                         //console.log(response.data)
                         this.$store.commit("setLoading", false);
                         if (response && response.data && response.data.token) {
                              this.$store.commit("setUser", {
                                   "user": username,
                                   "type": "manager",
                                   "nom": this.$msal.data.user.name
                              });
                              this.$store.dispatch("refreshCollabs")
                              this.$router.push('adminProjets');
                         }else{
                              this.$msal.signOut()
                         }
                    }).catch((e) => {
                         this.$store.commit("setLoading", false);
                         console.log(e)
                    })
               }else {
                    this.$store.commit("setLoading", false);
               }
          }else {
               this.$store.commit("setLoading", false);
          }
     },
     methods: {
          async login() {
               if(window.location.href.includes("id_token=")){
                    this.$router.replace('/')
               }
               this.$store.commit("setLoading", true);
               if (this.$msal.isAuthenticated()) {
                    this.$msal.acquireToken()
               } else {
                    console.log("signing in")
                    this.$msal.signIn()
               }
          },
          showPassword() {
               if (this.passwordFieldType == "password") {
                    this.passwordFieldType = "text";
                    this.icon = "eye-slash";
               } else {
                    this.passwordFieldType = "password";
                    this.icon = "eye";
               }
          },
          onSubmit(event) {
               event.preventDefault();
               let data = new FormData();
               data.set('user', this.username)
               data.set('password', this.password)
               this.error = false;
               this.axios
                    .post(
                         "/auth.php",
                         data
                    )
                    .then((response) => {
                         if (response.data?.status == 200) {
                              this.error = false;
                              if (this.rememberMe) {
                                   localStorage.setItem("userName", this.username);
                                   localStorage.setItem("passWord", this.password);
                                   sessionStorage.setItem("userName", this.username);
                                   sessionStorage.setItem("passWord", this.password);
                                   localStorage.setItem("rememberMe", true);
                              } else {
                                   localStorage.setItem("userName", "");
                                   localStorage.setItem("passWord", "");
                              }
                              this.$store.commit("setUser", response.data);
                              if (response.data.type == 'collab') {
                                   this.$store.commit("setLoading", false);
                                   this.$router.push('projets')
                              }
                         } else {
                              this.error = true;
                              this.$store.commit("setUser", {});
                         }
                    });
          },
     },
};
</script>

<style scoped>
.btn-microsoft {
     background-color: #eb3c00;
     color: white;
     border: none;
     border-radius: 3px;
     padding: 5px 20px
}

.btn-microsoft-logo {
     padding: 5px 20px 5px 0;
     border-right: 1px solid white;
}

.wobble-hor-bottom {
     -webkit-animation: wobble-hor-bottom 0.4s both;
     animation: wobble-hor-bottom 0.4s both;
}

@-webkit-keyframes wobble-hor-bottom {

     0%,
     100% {
          -webkit-transform: translateX(0%);
          transform: translateX(0%);
          -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
     }

     15% {
          -webkit-transform: translateX(-10px) rotate(-2deg);
          transform: translateX(-10px) rotate(-2deg);
     }

     30% {
          -webkit-transform: translateX(5px) rotate(2deg);
          transform: translateX(5px) rotate(2deg);
     }

     45% {
          -webkit-transform: translateX(-5px) rotate(-1.2deg);
          transform: translateX(-5px) rotate(-1.2deg);
     }

     60% {
          -webkit-transform: translateX(3px) rotate(0.8deg);
          transform: translateX(3px) rotate(0.8deg);
     }

     75% {
          -webkit-transform: translateX(-2px) rotate(-0.4deg);
          transform: translateX(-2px) rotate(-0.4deg);
     }
}

@keyframes wobble-hor-bottom {

     0%,
     100% {
          -webkit-transform: translateX(0%);
          transform: translateX(0%);
          -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
     }

     15% {
          -webkit-transform: translateX(-10px) rotate(-2deg);
          transform: translateX(-10px) rotate(-2deg);
     }

     30% {
          -webkit-transform: translateX(5px) rotate(2deg);
          transform: translateX(5px) rotate(2deg);
     }

     45% {
          -webkit-transform: translateX(-5px) rotate(-1.2deg);
          transform: translateX(-5px) rotate(-1.2deg);
     }

     60% {
          -webkit-transform: translateX(3px) rotate(0.8deg);
          transform: translateX(3px) rotate(0.8deg);
     }

     75% {
          -webkit-transform: translateX(-2px) rotate(-0.4deg);
          transform: translateX(-2px) rotate(-0.4deg);
     }
}
</style>

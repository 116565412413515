"use strict";

import axios from "axios";


let config = {
  //baseURL: 'http://localhost:8888/'
  baseURL: 'https://v-comp.groupe-vital.com'
};

const _axios = axios.create(config);

_axios.defaults.headers = {
  Authorization: `Bearer ${localStorage.getItem("AUTHORIZATION")}`
}

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

export default _axios

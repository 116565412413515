<template>
     <div id="app">
          <b-row class="py-1 head pl-lg-5 pr-lg-4 px-md-2 d-flex justify-content-between">
               <div class="my-auto">
                    <div @click="$router.push('/')" class="logo">
                         <img src="@/assets/globe.jpg" height="50px" />Vcomp
                    </div>
               </div>
               <div class="my-auto">
                    <div class="">
                         <b-dropdown variant="link" no-caret toggle-class="text-decoration-none" class="mt-0"
                              v-if="$store.state.user.user">
                              <template #button-content>
                                   <b-avatar size="2.2rem" variant="info">
                                        {{
                                             $store.state.user.type == 'manager' ? ($store.state.user.user[0] +
                                                  $store.state.user.user[1]).toUpperCase() : ($store.state.user.prenom[0] +
                                                       $store.state.user.nom[0]).toUpperCase()
                                        }}</b-avatar>
                              </template>
                              <b-dropdown-header id="dropdown-header-label">
                                   {{ $store.state.user.nom }}
                              </b-dropdown-header>
                              <b-dropdown-item to="/" @click="disconnect">
                                   <b-icon icon="power" /> Se déconnecter
                              </b-dropdown-item>
                         </b-dropdown>
                    </div>
               </div>
          </b-row>
          <b-overlay :show="$store.state.loading" variant="light" opacity=".5" blur="3px" spinner-variant="info"
               spinner-type="grow" z-index="99999" class="px-lg-5 px-md-3 py-md-2">
               <transition name="fade" mode="out-in">
                    <router-view />
               </transition>
          </b-overlay>
     </div>
</template>

<script>

export default {
     data() {
          return {
               search: ""
          }
     },
     methods: {
          async disconnect() {
               this.$store.dispatch("newStore");
               localStorage.clear();
               if (this.$msal.isAuthenticated()) {
                    await this.$msal.signOut()
               }
          },
          async resetSearch(e) {
               e.preventDefault()
               await new Promise(r => setTimeout(r, 100));
               this.search = ""
          },
          selectProject(projet) {
               this.$store.commit("setSelectedAdminProjectID", projet.ID);
               this.$store.dispatch("refreshSelectedAdminProject");
               this.$router.push("/adminProjets/" + projet.ID + '/general');
          }
     },
     created() {
          if (sessionStorage.getItem('store')) {
               this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
          }
          window.addEventListener('beforeunload', () => {
               sessionStorage.setItem('store', JSON.stringify(this.$store.state))
          })
          if (this.$route.name != "Login" && !this.$store.state.user?.user?.length) {
               this.$router.push("/");
               this.$store.dispatch("newStore");
               localStorage.clear();
          }
     },
     computed: {
          filteredList() {
               return this.$store.state.adminProjects.filter((projet) => {
                    return projet.title.toLowerCase().includes(this.search.toLowerCase());
               }).slice(0, 5);
          }
     },
     watch: {
          $route() {
               this.search = ""
          }
     }
};
</script>


<style lang="scss">
html {
     margin: 0 auto;
     height: 100%;
     width: 100%;
}

body {
     margin: 0;
     padding: 0;
     width: 100%;
     height: 100%;
}

#app {
     height: inherit;
     width: inherit;
     padding: 0 15px;
     background-color: rgba(245, 245, 247);
     overflow: auto;
}

.head {
     background-color: white;
     box-shadow: 0px 5px 10px -4px rgba(0, 0, 0, 0.05);
}

.logo {
     font-weight: 600;
     font-size: 22px;
     color: rgb(42, 42, 171);
     cursor: pointer
}

.search {
     position: absolute;
     top: 45px;
     right: 5px;
     max-width: 600px;
     width: 100%;
     min-width: 300px;
     z-index: 99999999999;
     font-size: 14px;
}

.fade-enter-active,
.fade-leave-active {
     transition: opacity 0.15s ease;
}

.fade-leave-to {
     opacity: 0;
}

.fade-enter-from {
     opacity: 1;
}

.pointer {
     cursor: pointer;
}

.b-avatar img {
     width: 100%;
     height: 100%;
}

::-webkit-scrollbar-track {
     background-color: rgba(0, 0, 0, 0.02);
}

::-webkit-scrollbar {
     border: 1px solid rgba(0, 0, 0, 0.05);
     width: 10px;
     background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
     background-color: rgba(0, 100, 150, 0.8);
}
</style>
